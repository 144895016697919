$(document).ready(function() {
    $('.slick-content').slick({
        dots: true,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        nextArrow: '<i class="slick-next fa fa-angle-right fa-2x"></i>',
        prevArrow: '<i class="slick-prev fa fa-angle-left fa-2x"></i>',
    });

    $('.slick-sponsors').slick({
        infinite: true,
        slidesToShow: 4,
        slidesToScroll: 1,
        nextArrow: '<i class="slick-next fa fa-angle-right fa-2x"></i>',
        prevArrow: '<i class="slick-prev fa fa-angle-left fa-2x"></i>',
        responsive: [
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                },
            },
        ],
    });

    $('.slick-sponsors-foundation').slick({
        infinite: true,
        slidesToShow: 5,
        slidesToScroll: 1,
        nextArrow: '<i class="slick-next fa fa-angle-right fa-2x"></i>',
        prevArrow: '<i class="slick-prev fa fa-angle-left fa-2x"></i>',
        responsive: [
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                },
            },
        ],
    });

    $('.slick-services').slick({
        dots: false,
        prevArrow: false,
        nextArrow: false,
    });

    $('.services-section .services-menu.no-click-through .menu-list a').click(function(e) {
        e.preventDefault();
        slideIndex = $(this).index();
        $('.slick-services').slick('slickGoTo', parseInt(slideIndex));
    });

    $('.slick-timeline-events, .slick-events').slick({
        dots: true,
        infinite: true,
        nextArrow: '<i class="slick-next fa fa-angle-right fa-2x"></i>',
        prevArrow: '<i class="slick-prev fa fa-angle-left fa-2x"></i>',
    });

});
